// import StakingCard from "components/staking_card";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
const linkd = window.location.origin
console.log('====================================');
console.log(linkd);
console.log('====================================');
const Home = () =>
{
  // const state = useSelector((state) => state);
  useEffect(() =>
  {
    document.title = 'Groks';
  });
  const [priceRoc, setPriceRoc] = useState('0')

  // useEffect(() =>
  // {
  //   const genRandomKey = async () =>
  //   {
  //     try {
  //       const ress = await fetch(`https://api.pancakeswap.info/api/v2/tokens/0x2A8E0eF7C92D52Cd7E388E390b646B213eA0c1EF`).then((res) => res.json());

  //       let price = ress.data.price * 1000000000;
  //       price = Number(price.toFixed(0))
  //       price = price.toLocaleString(undefined, {
  //         minimumFractionDigits: 0,
  //         maximumFractionDigits: 2
  //       });
  //       console.log(price);
  //       setPriceRoc(price);
  //     } catch (err) {
  //       setPriceRoc(0);
  //       console.log(`err`, err);
  //       return false;
  //     }
  //     // let res;
  //     setInterval(async () =>
  //     {
  //       try {
  //         const ress = await fetch(`https://api.pancakeswap.info/api/v2/tokens/0x2A8E0eF7C92D52Cd7E388E390b646B213eA0c1EF`).then((res) => res.json());
  //         let price = ress.data.price * 1000000000;
  //         price = Number(price.toFixed(0))
  //         console.log(price);
  //         price = price.toLocaleString(undefined, {
  //           minimumFractionDigits: 0,
  //           maximumFractionDigits: 2
  //         });
  //         console.log(price);
  //         setPriceRoc(price);
  //         return false;
  //       } catch (err) {
  //         setPriceRoc(0);
  //         console.log(`err`, err);
  //         return false;
  //       }
  //     }, 5000);
  //     return true;
  //   };

  //   genRandomKey();

  // }, []);

  return (
    <div className="home">

      <div className="sc-liccgK gWvivu">
        <div className="sc-cuWdqJ dMMUfh">AI Bot Trade - Things under your control!</div>
        <div className="sc-fTACoA EQGTL">Everyone understands that it is possible to make a fortune on cryptocurrency, but few are ready to sit at the monitor around the clock, peer at charts and analyze the market. That why we create EA + AI Bot Trade.</div>
        <div className="mt-3">
          <img className="circle me-2" width={26} src="https://poocoin.app/images/logos/bscscan.png" height="16" />
          <a target={"_blank"} href="https://bscscan.com/address/">Contract: </a>
        </div>
        <div className="sc-gfaqzF jLXwJt">
          {/* <img src="/assets/images/Launch Pad.png" alt="alita cuttle" width="110" className="sc-bTRMho gCqjPz" /> */}
          <img src={linkd + "/assets/images/logo_text.png"} alt="alita whale" width="400" className="sc-hRxcUd kKwDKt" style={{ top: 30, right: 150 }} />
          {/* <img src="/assets/images/sea-turtle.png" alt="alita sea turtle" width="182" className="sc-fTNIjK mVaYx" /> */}
        </div>
        <div className="sc-huZMQ hOxyXp">
          <div className="sc-hguplt cFLNLF">
            <img src="/assets/images/Total Value Locked (TVL).png" alt="alita" width="80px" />
            <div className="sc-jSgvzq sc-gKseQn ggnzcf jsAuFh">
              <div className="sc-lgqlnP hIoteP">Total Value Locked (TVL)</div>
              <div color="primary" font-size="36px" font-weight="bold" className="sc-gsTEea gNutHS">
                <span>$</span><span>0</span></div></div></div><div className="sc-fiKUBa jXTmSj">
            <img src="/assets/images/Market Cap.png" alt="alita" width="80px" />
            <div className="sc-jSgvzq sc-gKseQn ggnzcf jsAuFh">
              <div className="sc-lgqlnP hIoteP">Market Cap</div><div color="primary" font-size="36px" font-weight="bold" className="sc-gsTEea gNutHS">
                <span>$</span><span>{priceRoc}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="sc-kGNyvp gGcjQT">
        <div font-size="26px" font-weight="bold" color="text" class="sc-gsTEea juCEnc">Groks <br />The #1 AMM and yield farm</div>
        <div class="sc-jSgvzq sc-gKseQn sc-bAffKu exnhgn mSYCl hyPsDb">
          <div class="sc-jSgvzq sc-gKseQn exnhgn mSYCl">
            <div color="#000000" font-size="26px" font-weight="bold" class="sc-gsTEea jrYiTI"><span>1.000.000.000</span>
            </div>
            <div font-size="14px" color="#9EABBD" class="sc-gsTEea envsdP">Max Supply</div>
          </div>
          <div class="sc-jSgvzq sc-gKseQn exnhgn mSYCl">
            <div color="#000000" font-size="26px" font-weight="bold" class="sc-gsTEea jrYiTI"><span>1.000.000.000</span>
            </div>
            <div font-size="14px" color="#9EABBD" class="sc-gsTEea envsdP">Circulating Supply</div>
          </div>
          <div class="sc-jSgvzq sc-gKseQn exnhgn mSYCl">
            <a href="https://bscscan.com/token/?a=0x000000000000000000000000000000000000dead" target="_blank" rel="noreferrer noopener">
              <div color="#000000" font-size="26px" font-weight="bold" class="sc-gsTEea jrYiTI"><span>0</span>
              </div>
              <div font-size="14px" color="#9EABBD" class="sc-gsTEea envsdP">
                <span style={{ marginRight: 5, verticalAlign: 'middle' }}>
                  <img src="https://app.alita.finance/images/home/burned-icon.svg" alt="burned" width="16px" /></span>Burned
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
  );
};

export default Home;

import { applyMiddleware, createStore } from "redux";
import { combineReducers } from "redux";
import thunk from "redux-thunk";
import address from "./address";
import user_info from "./user_info";
import balance from "./balance";

const all = combineReducers({
  address,
  user_info,
  balance,
});

export default createStore(all, applyMiddleware(thunk));

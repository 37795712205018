import React from "react";
import Footer from "./Footer";
import Header from "./Header";

import { BrowserRouter, Route, Switch, Redirect, useHistory, useLocation, useRouteMatch } from "react-router-dom";
const Layout = ({ children, title }) => {
  const history = useHistory();
  const location = useLocation();
  const match = useRouteMatch();
  return (
    <>
      <Header />
      <div className="bg_over"></div>
      <main className="site-main-content container-fluid right_main" style={{ paddingTop: "10px" }}>
        <div className="breacrum___">

          <div>
            <span>
              <img src="/assets/images/logo_text.png" class="logo" alt="phishing-warning" style={{ width: 30 }} />
            </span>
            <span style={{ marginLeft: "0px" }}> { location.pathname}</span>
          </div>
        </div>
        <div className="staking-block container p-0" >
          <div className="page-head staking-block__header" style={{ display: "none" }}>
            <h2 className="header-title">{title}</h2>
            <div
              className="buttons"
              data-bs-toggle="tooltip"
              data-bs-placement="bottom"
              data-bs-html="true"
              title=" Displayed data will auto-refresh after <span className='timer'>12 seconds</span>. Click this circle to update manually. "
            >
              <div className="progress progress-circle">
                {/* <img src="/assets/images/header-circle-progess.svg" alt="" /> */}
              </div>
            </div>
          </div>
          {children}
        </div>
      </main>
      {/* <Footer /> */}
    </>
  );
};

export default Layout;

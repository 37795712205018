import StakingCard from "components/staking_card";
import React, { useEffect } from "react";
// import { useSelector } from "react-redux";

const Staking = () => {
  // const state = useSelector((state) => state);
  useEffect(() => {
    document.title = 'Staking';
  });
  return (
    <div className="staking-block__content">
      <h3 style={{ textAlign: 'center', marginTop: 70 }}>No data!!!</h3>
      {/* <StakingCard name={"10 Days"} endDay={10} apr={10/30} max={'1,000,000 AIT'} numMax={1000000} />
      <StakingCard name={"30 Days"} endDay={30} apr={15/30} max={'500,000 AIT'} numMax={500000} />
      <StakingCard name={"60 Days"} endDay={60} apr={20/30} max={'500,000 AIT'} numMax={500000} />
      <StakingCard name={"180 Days"} endDay={180} apr={30/30} max={'300,000 AIT'} numMax={300000} /> */}
    </div>
  );
};

export default Staking;

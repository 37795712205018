// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { NotificationManager } from "react-notifications";
import {
  getLv1Amount,
  getLv2Amount,
  getLv3Amount,
  getLv4Amount,
  checkApproveBuyNft,
  fcBuyCard,
  submitApproveBuyNft
} from '../../../service';

const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const BUYNFT = (props) => {
  useEffect(() => {
    document.title = 'Shop NFTs | AIT';
  });
  const state = useSelector((state) => state);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [approveBuyNft, setApproveBuyNft] = useState(false);


  const [amountSell, setAmountSell] = useState(0);

  const [isData, setIsData] = useState({ type: 1, price: 9000 });


  const [amountEgg, setAmountEgg] = useState({ lv1: 0, lv2: 0, lv3: 0, lv4: 0 });

  useEffect(() => {
    // setInterval(() => {
    //   getData();
    // }, 1000);
  }, []);

  // useEffect(() => {
  //   getNft()
  //   setInterval(() => {
  //     getNft()
  //   }, 5000);
  // }, []);


  // const getNft = async () => {
  //   const listNft = await getListNftMy();
  //   // console.log(listNft);
  //   let arr = [];
  //   let listNfts = '';
  //   for (let index = 0; index < listNft.length; index++) {
  //     const element = listNft[index];
  //     if (index != listNft.length - 1) {
  //       listNfts += (element + ',')
  //     } else {
  //       listNfts += (element)
  //     }
  //   }
  //   try {
  //     const res = await fetch(`https://api.AIT.xyz/AIT_infoNfts?data=${listNfts}`).then((res) => res.json());
  //     if (res) {
  //       // console.log(res);
  //       // arr.push(res)
  //       arr = res.data
  //     }
  //   } catch (err) {
  //     console.log(`err`, err);
  //     // return false;
  //   }
  //   // console.log(arr);
  //   setMyNft(arr)

  // };

  const getData = async () => {
    const amlv1 = await getLv1Amount();
    const amlv2 = await getLv2Amount();
    const amlv3 = await getLv3Amount();
    const amlv4 = await getLv4Amount();
    setAmountEgg({
      lv1: amlv1, lv2: amlv2, lv3: amlv3, lv4: amlv4
    })
  };


  const sell = async (data) => {
    return;
    setIsData(data)
    data.typeCuren = 'sell';
    console.log(data);
    const _checkApproveNft = await checkApproveBuyNft();
    console.log(_checkApproveNft);
    setApproveBuyNft(_checkApproveNft.stt)
    handleShow();
  }

  const _approveNft = async () => {
    setLoading(true);
    await submitApproveBuyNft()
    const _checkApproveNft = await checkApproveBuyNft();
    setApproveBuyNft(_checkApproveNft.stt)
    setLoading(false);
  }

  const _buyNft = async () => {
    setLoading(true);

    let data = await fcBuyCard(isData.type, Number(amountSell));
    console.log(data);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Buy Success", "Success", 10000);
      handleClose()
    }
    // await approveBuyNft()
    // const _checkApproveNft = await checkApproveBuyNft();
    // setApproveBuyNft(_checkApproveNft.stt)
    setLoading(false);
  }
  const handChange = (evt) => {
    const BNB = evt.target.value;
    console.log(BNB);
    setAmountSell(BNB)
  };

  return (
    <div className="site-main-content__buy-sft" >
      <div className="container">
        <div className="row manage-your-wallet">
          <div className="manage-your-wallet-title">
            <h2 className="wallet-title" style={{ color: '#252525' }}>
              Shop NFTs
            </h2>
          </div>

          <div className="col col-12 col-sm-3 mt-4">
            <div className="sadasdasds">
              <div className="sadds">
                <img className="imgsss" src={`/assets/images/logo_text.png`} />
                <hr />
                <div className="price mb-2 ">Availability NFTs: {amountEgg.lv1}</div>
                <div className="price mb-4">Price: 9,000 AIT</div>
                <div className="row m-0 harvest">
                  <div className="col-12 p-0" onClick={() => { sell({ type: 1, price: 9000 }) }}>
                    <button className="btn btn-lg btn-block btn-background-ghost">BUY</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-12 col-sm-3 mt-4">
            <div className="sadasdasds">
              <div className="sadds">
                <img className="imgsss" src={`/assets/images/logo_text.png`} />
                <hr />
                <div className="price mb-2 ">Availability NFTs: {0}</div>
                <div className="price mb-4">Price: 10,000 AIT</div>
                <div className="row m-0 harvest">
                  <div className="col-12 p-0" onClick={() => { sell({ type: 2, price: 16000 }) }}>
                    <button className="btn btn-lg btn-block btn-background-ghost">BUY</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-12 col-sm-3 mt-4">
            <div className="sadasdasds">
              <div className="sadds">
                <img className="imgsss" src={`/assets/images/logo_text.png`} />
                <hr />
                <div className="price mb-2 ">Availability NFTs: {amountEgg.lv3}</div>
                <div className="price mb-4">Price: 15,000 AIT</div>
                <div className="row m-0 harvest">
                  <div className="col-12 p-0" onClick={() => { sell({ type: 3, price: 33000 }) }}>
                    <button className="btn btn-lg btn-block btn-background-ghost">BUY</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col col-12 col-sm-3 mt-4">
            <div className="sadasdasds">
              <div className="sadds">
                <img className="imgsss" src={`/assets/images/logo_text.png`} />
                <hr />
                <div className="price mb-2 ">Availability NFTs: {amountEgg.lv4}</div>
                <div className="price mb-4">Price: 50,000 AIT</div>
                <div className="row m-0 harvest">
                  <div className="col-12 p-0" onClick={() => { sell({ type: 4, price: 90000 }) }}>
                    <button className="btn btn-lg btn-block btn-background-ghost">BUY</button>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: '#ff4d4d' }}>
          <div style={{ margin: 'auto', textAlign: 'center', marginBottom: 10, position: 'relative' }}>
            <img className="imgsss" src={`/assets/images/${isData?.type}.png`} style={{ width: '70%' }} />

          </div>
        </Modal.Body>
        {
          loading ? (
            <LoadingButton className="btn btn-primary" />
          ) : (
            <Modal.Footer>
              <div className="row" style={{ width: '100%' }}>
                <input
                  type="text"
                  className="col-5 bsfffff"
                  placeholder="0"
                  onChange={handChange}
                  value={amountSell}
                />
                <div className="col-1 p-0"></div>
                <div className="col-6 p-0" style={{ textAlign: 'right' }}>
                  {
                    approveBuyNft ?
                      <Button variant="primary" onClick={_buyNft}>
                        BUY ( {Number(isData?.price * amountSell).toFixed()} AIT)
                      </Button>
                      :
                      <Button variant="primary" onClick={_approveNft}>
                        APPROVE
                      </Button>
                  }
                </div>
              </div>


            </Modal.Footer>
          )

        }
      </Modal>
    </div>
  );
};

export default BUYNFT;

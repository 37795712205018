import Layout from "components/Layout";
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import { routers } from "router";
import { setBalance } from "store/balance/action";
import { get_user_info } from "store/user_info/action";

const AppRoute = ({ component: Component, exact, title, ...rest }) =>
{

  useEffect(() =>
  {
    const src = "/assets/js/etmx_scripts.js";
    const script = document.createElement("script");
    script.src = src;
    script.async = true;

    document.head.appendChild(script);
    return () =>
    {
      document.head.removeChild(script);
    };
  }, [title]);

  return (
    <Route
      exact={exact}
      {...rest}
      render={(props) => (
        <Layout title={title}>
          <Component {...props} />
        </Layout>
      )}
    />
  );
};

const Pages = () =>
{
  const dispatch = useDispatch();
  const state = useSelector((state) => state);
  const savedCallback = useRef();

  // useEffect(() =>
  // {
  //   // window.localStorage.setItem('typeConnect', type)
  //   if (window.localStorage.getItem('typeConnect') == 'bitkeep') {
  //     if (window.bitkeep) {
  //       window?.bitkeep?.ethereum.enable().then(() => { });
  //     }
  //   } else {
  //     if (window.ethereum) {
  //       window.ethereum.enable().then(() => { });
  //     }
  //   }

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  // useEffect(() => {
  //   if (state.address) {
  //     // dispatch(get_user_info(state.address.toLowerCase()));
  //     // dispatch(setBalance(state.address));
  //     if (state.address !== savedCallback.current) {
  //       clearInterval(savedCallback.current);
  //     }
  //     savedCallback.current = state.address;
  //     // savedCallback.current = setInterval(() => {
  //     //   dispatch(setBalance(state.address));
  //     //   dispatch(get_user_info(state.address.toLowerCase()));
  //     // }, 5000);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state.address]);
  // console.log(history);
  // console.log(location);
  // console.log(match);
  return (
    <BrowserRouter>

      <Switch>
        {routers.map((e, i) => (
          <AppRoute
            key={i}
            path={e.path}
            component={e.component}
            exact={e.exact}
            title={e.title}
          />
        ))}
        <Redirect from="*" to="/mint-nft" />
      </Switch>
    </BrowserRouter>
  );
};

export default Pages;

import React, { useState, useEffect, useCallback } from 'react';
// import { Howl } from 'howler';
import { buyBox, formatNumber, getInfoMint, groksMint } from 'service';
import Modal from 'react-bootstrap/Modal'
import { NotificationManager } from 'react-notifications';
import { useRef } from 'react';
import Web3 from 'web3';

const LoadingButton = ({ className }) => {
  return (
    <button className={className} style={{ width: 200 }}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const colors = [1, 2, 3];
const delay = 2500;

const App = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [indexImg, setIndexImg] = useState(0);
  const [typeBox, setTypeBox] = useState(3);
  const [arrImg, setArrImg] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showModalReward, setShowModalReward] = useState(false);
  const [dataReward, setDataReward] = useState(0);
  const [dataMint, setDataMint] = useState({});
  const [vlOW, setVlOW] = useState("");
  let loadedCount = 0;
  const totalImages = 107; // Số lượng ảnh cần tải
  let intervalId;

  useEffect(() => {
    document.title = 'Mint NFTs';
  });



  const [remainingTimePublic, setRemainingTimePublic] = useState(0);
  const timerRef = useRef(null);

  useEffect(() => {
    const countdown = () => {
      setRemainingTimePublic(prevTime => prevTime - 1);
    };
    timerRef.current = setInterval(countdown, 1000);
    return () => {
      clearInterval(timerRef.current);
    };
  }, [remainingTimePublic]);

  const formatTime = (time) => {
    const days = Math.floor(time / (3600 * 24));
    const hours = Math.floor((time % (3600 * 24)) / 3600);
    const minutes = Math.floor((time % 3600) / 60);
    const seconds = time % 60;
    return `${String(days).padStart(2, '0')}:${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
  };

  const timePublicString = formatTime(Math.max(remainingTimePublic, 0));

  const [index, setIndex] = React.useState(0);
  const timeoutRef = useRef(null);

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setIndex((prevIndex) =>
          prevIndex === colors.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );

    return () => {
      resetTimeout();
    };
  }, [index]);



  useEffect(() => {
    let arr = [];
    for (let index = 0; index < 107; index++) {
      arr.push(index)
    }
    setArrImg(arr)
    startImageDownload();
  }, []);

  useEffect(() => {
    let arr = [];
    for (let index = 0; index < 107; index++) {
      arr.push(index)
    }
    setArrImg(arr)
    startImageDownload();
    setIndexImg(0)
  }, [typeBox]);

  useEffect(() => {
    setRemainingTimePublic(1703088000 - Math.round(Date.now() / 1000))
  }, []);

  const handleOpenClick = () => {
    return
    setShowModal(true);
  };

  useEffect(async () => {
    getData()
    const timer = setInterval(() => {
      getData()
    }, 1000);
    return () => clearInterval(timer);
  }, []);

  const getData = async () => {
    let data = await getInfoMint();
    // console.log(data);
    if (!data.totalMint) {
      return
    }
    setRemainingTimePublic((data.timeOpen * 1) - Math.round(Date.now() / 1000))
    setDataMint(data);
    // setApprove(data?.stt);
  }

  const startImageDownload = async () => {
    setIsDownloading(true);
    try {
      // Mảng chứa các promise từ việc tải từng ảnh
      const downloadPromises = Array.from({ length: totalImages }, (_, index) => {
        return loadImage(index);
      });

      // Sử dụng Promise.all để chờ tất cả các ảnh được tải xong
      await Promise.all(downloadPromises);
    } catch (error) {
      console.error('Error loading images:', error);
    } finally {
      setIsDownloading(false);
      setIsOpen(false); // Mở hộp quà khi tất cả ảnh đã được tải xong
    }
  };

  const loadImage = async (index) => {
    try {
      const response = await fetch(`/assets/images/lv${typeBox}/chest2-lv${typeBox}-open_${getindex(index)}.webp`);
      if (response.ok) {
        // Tăng số lượng ảnh đã tải
        loadedCount++;
        setLoadingProgress((loadedCount / totalImages) * 100);
      }
    } catch (error) {
      console.error('Error loading image:', error);
    }
  };

  const getindex = (id) => {
    if (id < 10) {
      return '00' + id;
    }
    if (id < 100) {
      return '0' + id;
    } else {
      return '' + id;
    }
  };


  const handleSubmit = async (event) => {
    const web3 = new Web3();
    event.preventDefault();
    // const bigNumber = web3.utils.toBN("0x0000000000000000000000000000000000000000000000000000000000000017");
    // const number11 = bigNumber.toString();
    // return

    setLoading(true);
    let data = await groksMint();
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
      setLoading(false)
      return
    } else {
      NotificationManager.success("Success", "Success", 10000);
    }
    // console.log(data);
    // console.log(data?.events[1]?.raw?.topics);
    // console.log(data?.events[1]?.raw?.topics?.[2]);
    const bigNumber = web3.utils.toBN(data?.events[1]?.raw?.topics?.[2]);
    const number = bigNumber.toString();
    // console.log(number);
    setDataReward(number)
    setShowModalReward(true)
    // console.log(data.events?.BuyNftsResult?.returnValues);
    // setDataReward(data.events?.BuyNftsResult?.returnValues)

    setLoading(false);
  };


  return (
    <div className="App" style={{ textAlign: "center" }}>
      <div style={{ height: 20 }}></div>

      <div className='' style={{ textAlign: 'center', color: '#252525' }}>
        {/* <h2 style={{ textAlign: 'center' }}>Countdown Timer</h2>
          <p style={{ textAlign: 'center', fontSize: 46, margin: 0 }}>
            {timeRemaining.hours}<span className='ssssaffa'>h </span>{timeRemaining.minutes}<span className='ssssaffa'>m </span>{timeRemaining.seconds}<span className='ssssaffa'>s</span>
          </p>
          <div>(2023-12-20 16:00 UTC)</div> */}
        <div className="lslslslsla mt-4 mb-4">
          <span style={{ color: '#f7931a' }}>
            Countdown Timer
          </span>
        </div>
        <div className="flex mt-4 mb-4">
          <div className="has-text-right">
            <div className="has-text-centered" style={{ textAlign: 'center' }}>
              <strong style={{ display: 'inline-flex', alignItems: 'center' }}>
                <div className="has-background-danger-light mr-1" >
                  {timePublicString.split(':')[0]}
                  <div className="lslsalopgof">DAYS</div>
                </div>
                <span className="lslslaodosad">:</span>
                <div className=" has-background-danger-light mr-1" >
                  {timePublicString.split(':')[1]}
                  <div className="lslsalopgof">HOURS</div>
                </div>
                <span className="lslslaodosad">:</span>
                <div className=" has-background-danger-light mr-1" >
                  {timePublicString.split(':')[2]}
                  <div className="lslsalopgof">MINUTES</div>
                </div>
                <span className="lslslaodosad">:</span>
                <div className=" has-background-danger-light mr-1" >
                  {timePublicString.split(':')[3]}
                  <div className="lslsalopgof">SECONDS</div>
                </div>
              </strong>
            </div>
          </div>
        </div>
        <div style={{ textAlign: 'center' }}>
          <span style={{ color: 'rgb(125 121 117)' }}>Mint NFTs opening date:</span> 16:00 on December 20th UTC
        </div>
        <div style={{ height: 50 }}></div>
        <div className='row m-0 '>
          <div className='col-sm-6 mb-4'>
            <div className='loasldjqwgerL'>
              <div className="slideshow">
                <div
                  className="slideshowSlider"
                  style={{ transform: `translate3d(${-index * 100}%, 0, 0)` }}
                >
                  {colors.map((backgroundColor, index) => (
                    <div className='lollaobmd slide'>
                      <img src={`/assets/images/nfts/${backgroundColor}.png`} />
                    </div>
                  ))}
                </div>
                <div className="slideshowDots">
                  {colors.map((_, idx) => (
                    <div
                      key={idx}
                      className={`slideshowDot${index === idx ? " active" : ""}`}
                      onClick={() => {
                        setIndex(idx);
                      }}
                    ></div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className='col-sm-6 mb-4'>
            <div className='ollkasows'>
              <img src="/assets/images/logo_text.png" class="logo mb-4 hidedMB hidedMBs" alt="phishing-warning" style={{ width: '100%', maxWidth: 200 }} />
              <div className='sadadeafd mt-4'>
                <div className='fgrlasddsa'>
                  NFTs available:
                </div>
                <div className='fgrlasddsa'>
                  {dataMint?.totalMint}/168
                </div>
              </div>
              <div>
                <progress style={{ width: '100%' }} value={`${1 - (dataMint?.totalMint / 168)}`} />
              </div>
              <div className='sadadeafd'>
                <div className='fgrlasddsa'>
                  Price:
                </div>
                <div className='fgrlasddsa'>
                  0.6 BNB
                </div>
              </div>
              <div className='sadadeafd'>
                <div className='fgrlasddsa'>
                  Note: Each wallet can only mint once
                </div>
                <div className='fgrlasddsa'>

                </div>
              </div>
              <div style={{ textAlign: 'center', marginTop: 30 }}>
                {
                  loading ?
                    <LoadingButton type="button" className="ant-btn ant-btn-primary btn btn-primary" />
                    :
                    (
                      (
                        <button style={{ width: '100%' }} className="ant-btn ant-btn-primary btn btn-primary" onClick={handleSubmit} disabled={isOpen}>
                          Mint
                        </button>
                      )
                    )
                }
              </div>
            </div>
          </div>
        </div>

        {/* <div className='lollls'>
            <div className='opllaowfs'>
              <a class="prevt">❮</a>
              <div className='lollaobmd'>
                <img src='/assets/images/nfts/1.png' />
              </div>
              <div className='lollaobmd'>
                <img src='/assets/images/nfts/1.png' />
              </div>
              <div className='lollaobmd'>
                <img src='/assets/images/nfts/1.png' />
              </div>
              <a class="prevs" >❯</a>
            </div>
          </div> */}
        {/* <div className='row m-0' style={{ alignItems: 'end' }}>
            <div className='col-sm-6 mb-4'>
              {
                arrImg.map((item, index) => {
                  return (
                    <img
                      className={indexImg != item ? "d-none" : ""}
                      style={{ maxWidth: 400, width: '100%' }}
                      key={index}
                      src={`/assets/images/v${typeBox}/chest2-lv${typeBox}-open_${getindex(item)}.webp`}
                      alt={`Image ${index}`}
                    />
                  )
                })
              }
              <div style={{ textAlign: 'center' }}>
                {
                  isOpen ?
                    <LoadingButton type="button" className="ant-btn ant-btn-primary btn btn-primary" />
                    :
                    (
                      (
                        <button style={{ width: 200 }} className="ant-btn ant-btn-primary btn btn-primary" onClick={handleOpenClick} disabled={isOpen}>
                          Mint
                        </button>
                      )
                    )
                }
              </div>
            </div>
            <div className='col-sm-6 mb-4'>
              <div className='ollkasows'>
                <img src="/assets/images/logo_text.png" class="logo mb-4 hidedMB hidedMBs" alt="phishing-warning" style={{ width: '100%', maxWidth: 200 }} />
                <div className='sadadeafd'>
                  <div className='fgrlasddsa'>
                    NFTs available:
                  </div>
                  <div className='fgrlasddsa'>
                    168/168
                  </div>
                </div>
                <div>
                  <progress style={{ width: '100%' }} value={0} />
                </div>
                <div className='sadadeafd'>
                  <div className='fgrlasddsa'>
                    Price:
                  </div>
                  <div className='fgrlasddsa'>
                    0.6 BNB
                  </div>
                </div>
                <div className='sadadeafd'>
                  <div className='fgrlasddsa'>
                    Note: Each wallet can only mint once
                  </div>
                  <div className='fgrlasddsa'>

                  </div>
                </div>
              </div>
            </div>
          </div> */}


      </div>
      {
        showModal ?
          <Modal show={true} onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{}}>Quantity</p>
              <input style={{ width: '100%' }} className="input" type="text" id="newOwner" name="newOwner" value={vlOW} onChange={(e) => setVlOW(e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
              <div className="has-text-centered">
                {
                  loading ?
                    <LoadingButton type="button" className="ant-btn ant-btn-primary btn btn-primary" />
                    :
                    (
                      <button type="submit" className="ant-btn ant-btn-primary btn btn-primary" onClick={handleSubmit}>
                        <span>Buy</span>
                      </button>

                    )
                }
              </div>
            </Modal.Footer>
          </Modal>
          : null
      }

      {
        showModalReward ?
          <Modal show={true} onHide={() => { setShowModalReward(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{}}>Congratulations on receiving</p>
              <div>
                <div className='row m-0 ooooosklsksksaa'>
                  <img style={{ maxWidth: 300, margin: 'auto' }} src={`/assets/images/nfts/${dataReward}.png`} />
                </div>
              </div>
            </Modal.Body>

          </Modal>
          : null
      }
    </div>
  );
};

export default App;








import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { routers } from "router";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'

const Header = ({ props }) => {
  const state = useSelector((state) => state);
  const [click, setCLick] = useState(false)
  const [priceRoc, setPriceRoc] = useState(0)
  const [nav__, setNav] = useState(false)
  // console.log(state);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [show, setShow] = useState(false);

  const _vrConnect = (type) => {
    // console.log(type);
    // if (window.bitkeep) {
    //   window.bitkeep.ethereum.enable().then((a) => {
    //     console.log(a);
    //     window.localStorage.setItem('isConnect', 'connect')
    //     window.localStorage.setItem('typeConnect', type)
    //     handleClose()
    //   });
    // }
    // return
    if (type == 'bitkeep') {
      // console.log('1111');
      if (window.bitkeep) {
        window?.bitkeep?.ethereum.enable().then(() => {
          window.localStorage.setItem('isConnect', 'connect')
          window.localStorage.setItem('typeConnect', type)
          handleClose()
        });
      } else {
        const provider = window.bitkeep && window.bitkeep.ethereum;
        if (!provider) {
          return window.open('https://bitkeep.com/download?type=0&theme=light');
        }
      }
    } else {
      // console.log('2222');
      if (window.ethereum) {
        window.ethereum.enable().then(() => {
          window.localStorage.setItem('isConnect', 'connect')
          window.localStorage.setItem('typeConnect', type)
          handleClose()
        });
      }
    }

  };
  const onConnect = () => {
    handleShow()
    return
    // if (window.ethereum) {
    //   window.ethereum.enable().then(() => {
    //     window.localStorage.setItem('isConnect', 'connect')
    //   });
    // }
  };
  const disConnect = () => {
    window.localStorage.setItem('isConnect', 'disConnect')
    window.localStorage.setItem('typeConnect', 'none')
  };

  // useEffect(() => {
  //   const genRandomKey = async () => {
  //     try {
  //       const ress = await fetch(`https://api.pancakeswap.info/api/v2/tokens/0x2A8E0eF7C92D52Cd7E388E390b646B213eA0c1EF`).then((res) => res.json());
  //       if (ress.data == undefined) {
  //         return
  //       }
  //       setPriceRoc(ress.data.price);
  //     } catch (err) {
  //       setPriceRoc(0.36);
  //       console.log(`err`, err);
  //       return false;
  //     }
  //     // let res;
  //     setInterval(async () => {
  //       try {
  //         const ress = await fetch(`https://api.pancakeswap.info/api/v2/tokens/0x2A8E0eF7C92D52Cd7E388E390b646B213eA0c1EF`).then((res) => res.json());
  //         if (ress.data == undefined) {
  //           return
  //         }
  //         setPriceRoc(ress.data.price);
  //         return false;
  //       } catch (err) {
  //         setPriceRoc(0.36);
  //         console.log(`err`, err);
  //         return false;
  //       }
  //     }, 5000);
  //     return true;
  //   };

  //   genRandomKey();

  // }, []);

  // const getPrice = async () => {
  //   try {
  //     const res = await fetch(`https://api.pancakeswap.info/api/v2/tokens/0x9Ea46142e60560167542DFF28c5e65AC980B9316`).then((res) => res.json());
  //     console.log(res.data.price);
  //     // if (res.stakeInfo.unpaid_balanceOld_ido_v3 == undefined) {
  //     //   res.stakeInfo.unpaid_balanceOld_ido_v3 = [0, 0]
  //     // }
  //     setPriceRoc(res.data.price);
  //   } catch (err) {
  //     setRefBy(0.136);
  //     console.log(`err`, err);
  //     return false;
  //   }
  // };

  return (
    <header className="site-header">



      <nav className={nav__ ? "navbar navbar-expand-lg left_main close" : "navbar navbar-expand-lg left_main"}>
        <div className="sc-55d3ba1b-3 dzYNbO">
          <div className="sc-5d6c008f-1 sc-1080dffc-0 sc-dc401a1a-0 hwMcXE bHahRd erbnUK warning-banner">
            <div className="sc-5d6c008f-1 sc-1080dffc-0 sc-dc401a1a-1 hwMcXE bHahRd hXkkuc">
              <div className="left">
                <div className="btn_bar">
                  <button
                    id="grmobile"
                    className="navbar-toggler header-menu-toggle"
                    type="button"

                    onClick={() => { setNav(!nav__) }}
                    style={{
                      padding: 0
                    }}
                  >
                    {nav__ ?

                      <i class="fa-regular fa-circle-xmark"></i>
                      :
                      <i class="fa-solid fa-bars"></i>

                    }
                  </button>
                </div>
                <a href="index.html" className="nuxt-link-active col-auto" style={{ display: 'flex', alignItems: 'center', color: ' rgb(158, 171, 189)', textDecoration: 'auto' }}>
                  <img src="/assets/images/logo_text.png" className="logo hidedMB hidedMBs" alt="phishing-warning" style={{ width: "50px" }} />
                  <span className='hidedMB hidedMBs' style={{ marginLeft: 5, fontSize: 20, fontWeight: 700 }}>Groks</span>
                </a>
              </div>
              {/* <div className="right">
                <div className="fs-container pl-2 pr-2" style={{ position: "unset", flexDirection: "row" }}>


                  <div className="btn-connect-walet">
                    {state.address ? (
                      <button className="btn btn-outline-secondary btn-add-coin" onClick={disConnect}>
                        <span className="button-title">
                          {state.address.slice(0, 6)}...{state.address.slice(-4)}
                        </span>
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn mr-2"
                        onClick={onConnect}
                      >
                        
                        <span>Connect</span>
                      </button>
                    )}
                  </div>

                </div>
              </div> */}
            </div>
          </div>
        </div>
        {/* <div id="nav-mobile__"> */}
        <div className={!click ? "container-fluid mobile__ " : "container-fluid mobile__ show "}>
          <div className="navbar-collapse collapse" id="navbarSupportedContent" style={{}}>
            <ul role="menu" className="navbar-nav menu menu-horizontal menu-root menu-light" id="menushowbar">


              <li role="menuitem" className="nav-item">
                <Link className="nav-link" title="Mint NFT" to='/mint-nft' style={{ width: '100%' }}>
                  <span className="icon_menu">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="mr-3 flex-shrink-0 h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 01-.657.643 48.39 48.39 0 01-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 01-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 00-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 01-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 00.657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 01-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 005.427-.63 48.05 48.05 0 00.582-4.717.532.532 0 00-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 00.658-.663 48.422 48.422 0 00-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 01-.61-.58v0z"></path></svg>
                  </span>
                  <span className={nav__ ? "text_menu" : ""}>Mint NFTs</span>
                </Link>
              </li>

              <li role="menuitem" className="nav-item">
                <Link to="/nfts" className="nav-link" title="NFTs" style={{ width: '100%' }}>
                  <span className="icon_menu">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="mr-3 flex-shrink-0 h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M21 12a2.25 2.25 0 00-2.25-2.25H15a3 3 0 11-6 0H5.25A2.25 2.25 0 003 12m18 0v6a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 18v-6m18 0V9M3 12V9m18 0a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 9m18 0V6a2.25 2.25 0 00-2.25-2.25H5.25A2.25 2.25 0 003 6v3"></path></svg>
                  </span>
                  <span className={nav__ ? "text_menu" : ""} style={{  }}>Bag</span>
                </Link>
              </li>

              <li role="menuitem" className="nav-item">
                <Link className="nav-link" title="Market" to='/market' style={{ width: '100%' }}>
                  <span className="icon_menu">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="mr-3 flex-shrink-0 h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6A2.25 2.25 0 016 3.75h2.25A2.25 2.25 0 0110.5 6v2.25a2.25 2.25 0 01-2.25 2.25H6a2.25 2.25 0 01-2.25-2.25V6zM3.75 15.75A2.25 2.25 0 016 13.5h2.25a2.25 2.25 0 012.25 2.25V18a2.25 2.25 0 01-2.25 2.25H6A2.25 2.25 0 013.75 18v-2.25zM13.5 6a2.25 2.25 0 012.25-2.25H18A2.25 2.25 0 0120.25 6v2.25A2.25 2.25 0 0118 10.5h-2.25a2.25 2.25 0 01-2.25-2.25V6zM13.5 15.75a2.25 2.25 0 012.25-2.25H18a2.25 2.25 0 012.25 2.25V18A2.25 2.25 0 0118 20.25h-2.25A2.25 2.25 0 0113.5 18v-2.25z"></path></svg>
                  </span>
                  <span className={nav__ ? "text_menu" : ""}>Market</span>
                </Link>
              </li>

              
              <li role="menuitem" className="nav-item">
                <Link className="nav-link" title="Staking" to="/staking" style={{ width: '100%' }}>
                  <span className="icon_menu">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="mr-3 flex-shrink-0 h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"></path></svg></span>
                  <span className={nav__ ? "text_menu" : ""}>Staking NFTs</span>
                </Link>
              </li>

              <li role="menuitem" className="nav-item">
                <a target="_black" href="https://pancakeswap.finance/swap?outputCurrency=0x07bF0cbcBb9230a16A5c134aFCC6b51eD3235D57" style={{ textDecoration: 'none', width: '100%' }}>
                  <span className="icon_menu">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="mr-3 flex-shrink-0 h-6 w-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3"></path></svg>
                  </span>
                  <span className={nav__ ? "text_menu" : ""}>Swap</span>
                </a>
              </li>

            </ul>

          </div>

          <div className={nav__ ? "fs-container navbar_bottom text_menu " : "fs-container navbar_bottom"}>

            {/* <div className="sc-kYrlTI iRXOse">
              <a rel="noreferrer" href="https://blocksafu.com/project-detail/" target="_blank">
                <div className="sc-xyEDr esMtpe">Secured by</div>
                <div style={{ marginTop: 6, lineHeight: 'initial' }}>
                  <img alt="audit" src="https://blocksafu.com/images/logo-dark.svg" id='imgssss' />
                </div>
              </a>
            </div> */}
            <div className="sssfasdas">
              <div className="sc-xyEDr esMtpesgrs">Community</div>
              <div className="oldldkkd">
                <a target="_blank" style={{marginRight: 15}} href="https://twitter.com/groks_bsc">
                  <i class="fa-brands fa-twitter"></i>
                </a>
                <a target="_blank" href='https://t.me/groks_bsc'>
                  <i class="fa-brands fa-telegram"></i>
                </a>
                {/* <a target="_blank" href='https://t.me/aitradeChannel'>
                  <i class="fa-brands fa-telegram"></i>
                </a>
                <a target="_blank" href="">
                  <i class="fa-brands fa-github"></i>
                </a> */}
              </div>
            </div>
            <div className="btn-connect-walet sdsadsadgjy" style={{ width: "100%" }}>
              {state.address ? (
                <button className="btn btn-outline-secondary btn-add-coin" onClick={disConnect} style={{ width: "100%", position: 'relative' }}>
                  <span style={{ position: 'absolute', left: 5, top: 3 }}>
                    <svg className="sssaasd" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="w-6 h-6 text-primary"><path stroke-linecap="round" stroke-linejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"></path></svg>
                  </span>
                  <span className="button-title">
                    {state.address.slice(0, 6)}...{state.address.slice(-4)}
                  </span>
                </button>
              ) : (
                <button
                  style={{ width: "100%" }}
                  type="button"
                  className="btn mr-2"
                  onClick={onConnect}
                >

                  <span>Connect</span>
                </button>
              )}
            </div>
          </div>

        </div>

        <div className="container-fluid mobile">
          <div className="fs-container">
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                id="grmobile"
                className="navbar-toggler header-menu-toggle"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => { setCLick(!click) }}
                style={{ margin: "10px 0" }}
              >
                {click ?
                  <i class="fa-regular fa-circle-xmark"></i>
                  :
                  <i class="fa-solid fa-bars"></i>
                }
              </button>
              <a href="index.html" className="nuxt-link-active col-auto" style={{ display: 'flex', alignItems: 'center', color: ' rgb(158, 171, 189)', textDecoration: 'auto', marginLeft: "10px" }}>
                <img src="/assets/images/logo_text.png" className="logo hidedMB hidedMBs" alt="phishing-warning" style={{ width: "50px" }} />
                <span className='hidedMB hidedMBs' style={{ marginLeft: 5, fontSize: 20, fontWeight: 700 }}>Groks</span>
              </a>
            </div>
            <div className="gras row pr-2">
              <div className="btn-connect-walet col-6">
                {state.address ? (
                  <button className="btn btn-outline-secondary btn-add-coin" onClick={disConnect}>
                    <span className="button-title">
                      {state.address.slice(0, 6)}...{state.address.slice(-4)}
                    </span>
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn mr-2"
                    onClick={onConnect}
                  >
                    {/* <img src="/assets/images/icon-walet.svg" alt="" /> */}
                    <span>Connect</span>
                  </button>
                )}
              </div>

            </div>
          </div>

          {/* </div> */}
        </div>
      </nav>

      <Modal show={show} onHide={handleClose} style={{ maxWidth: 350, margin: 'auto' }}>
        <Modal.Header closeButton>
          <Modal.Title>Connect Wallet</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: '#ff4d4d' }}>
          <div className="row sssrsrs">
            <div className="col-6" onClick={() => { _vrConnect('metamask') }}>
              <img className="imgsss" src="https://seeklogo.com/images/M/metamask-logo-09EDE53DBD-seeklogo.com.png" style={{}} />
              <div>Metamask</div>
            </div>
            <div className="col-6" onClick={() => { _vrConnect('metamask') }}>
              <img className="imgsss" src="https://avatars.githubusercontent.com/u/18060234?s=280&v=4" style={{}} />
              <div>Coinbase Wallet</div>
            </div>
            <div className="col-6" onClick={() => { _vrConnect('metamask') }}>
              <img className="imgsss" src="https://s2.coinmarketcap.com/static/img/coins/200x200/5964.png" style={{}} />
              <div>Trust Wallet</div>
            </div>
            <div className="col-6" onClick={() => { _vrConnect('metamask') }}>
              <img className="imgsss" src="https://play-lh.googleusercontent.com/uT6ByyNvUeLRMDnMKEC91RrbHftl2EBB58r9vZaNbiYf1F5Twa33_Hx0zYvEfCtiG1kE" style={{}} />
              <div>Safepal</div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </header>
  );
};

export default Header;

// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { NotificationManager } from "react-notifications";
import {
  approveAllStakingNft, checkApproveStakingNft, fcStakingNft, packagesStakingNft, stakingNftWithdraw, unStakingNft,

  getListNftMy,
  approveAll,
  checkApproveNft,
  fcSellMarket,
  fcmining
} from '../../../service';

const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const NFTs = (props) => {
  useEffect(() => {
    document.title = 'NFTs | AIT';
  });

  const state = useSelector((state) => state);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [approveNftMarket, setApproveNftMarket] = useState(false);
  const [approveNftMining, setApproveNftMining] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [vlOW, setVlOW] = useState("");
  const [idCurrenSelect, setIdCurrenSelect] = useState("0");
  const [amountSell, setAmountSell] = useState(0);
  const [myNft, setMyNft] = useState([]);

  const [isData, setIsData] = useState({});


  // useEffect(() => {
  //   setInterval(() => {
  //     getData();
  //   }, 1000);
  // }, []);

  useEffect(() => {
    getNft()
    setInterval(() => {
      getNft()
    }, 5000);
  }, []);


  const getNft = async () => {
    const listNft = await getListNftMy();
    // console.log(listNft);
    setMyNft(listNft)

  };


  const sell = async (data) => {
    setIdCurrenSelect(data)
    setShowModal(true)
  }

  const mining = async (data) => {
    data.typeCuren = 'mining';
    console.log(data);
    const _checkApproveNft = await checkApproveNft(data.id);
    console.log(_checkApproveNft);
    setApproveNftMining(_checkApproveNft.sttMining)
    setIsData(data)
    handleShow();
  }


  const handChange = (evt) => {
    const BNB = evt.target.value;
    console.log(BNB);
    setAmountSell(BNB)
  };
  const _approveNft = async (val) => {
    setLoading(true);
    await approveAll(val)
    const _checkApproveNft = await checkApproveNft(isData.id);
    setApproveNftMarket(_checkApproveNft.sttMarket)
    setApproveNftMining(_checkApproveNft.sttMining)
    setLoading(false);
  };

  const _sellNft = async () => {
    // console.log(amountSell);
    // const _checkApproveNft = await checkApproveNft(data);
    // console.log(_checkApproveNft);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    setLoading(true)
    let _checkApproveNft = await checkApproveNft(idCurrenSelect);
    // console.log(ssaaf3243);
    // return
    if (!_checkApproveNft.sttMarket) {
      let dloasltr = await approveAll('sell');
      // if (!dloasltr) {
      //   setLoading(false)
      //   return
      // }
    }

    let data = await fcSellMarket(idCurrenSelect, (vlOW));
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Sell Success", "Success", 10000);
      // handleClose()
      setShowModal(false)
    }
    // const _checkApproveNft = await checkApproveNft(isData.id);
    // console.log(_checkApproveNft.sttMarket);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    setLoading(false);
  };

  const _miningNft = async (num) => {
    setLoading(true)

    let data = await fcStakingNft(isData.id, num);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Mining Success", "Success", 10000);
      handleClose()
    }
    // const _checkApproveNft = await checkApproveNft(isData.id);
    // console.log(_checkApproveNft.sttMarket);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    setLoading(false);
  };


  return (
    <div className="site-main-content__buy-sft">
      <div className="container">
        <div className="row manage-your-wallet">
          {/* <div className="manage-your-wallet-title">
            <h2 className="wallet-title" style={{ color: '#252525' }}>
              NFTs
            </h2>
          </div> */}
          {
            (myNft.length) == 0 ?
              <h3 style={{ textAlign: 'center', marginTop: 70 }}>No data!!!</h3>
              :
              myNft.map((data, key) => {
                return (
                  <div className="col col-12 col-sm-3 mt-4" key={key}>
                    <div className="sadasdasds">
                      <div className="sadds">
                        <img className="imgsss" src={`/assets/images/nfts/${data}.png`} />
                        {/* <img style={{ maxWidth: 300, margin: 'auto' }} src={`/assets/images/nfts/${dataReward}.png`} /> */}
                        <div className="price mb-2 mt-2">ID: {data}</div>
                        <div className="row m-0 harvest">
                          <div className="col-6 pl-0" >
                            <button disabled={true} className="btn btn-lg btn-block btn-background-ghost">Stake</button>
                          </div>
                          <div className="col-6 pr-0" onClick={() => { sell(data) }}>
                            <button className="btn btn-lg btn-block btn-background-ghost">Sell</button>
                          </div>
                          {/* <div className="col-6 pr-0">
                            <button className="btn btn-lg btn-block btn-background-ghost">Sell</button>
                          </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
          }
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: '#d0d0d0' }}>
          <div style={{ margin: 'auto', textAlign: 'center', marginBottom: 10, position: 'relative' }}>
            <img className="imgsss" src={isData?.image_url} style={{ width: '70%' }} />
          </div>
        </Modal.Body>
        {
          loading ? (
            <LoadingButton className="btn btn-primary" />
          ) : (
            <Modal.Footer>

              {/* <Button variant="secondary" onClick={() => confirmBuy(1)}>
                BUSD
              </Button> */}
              {
                isData?.typeCuren == 'sell' ?
                  <div className="row" style={{ width: '100%' }}>
                    <input
                      type="text"
                      className="col-8 bsfffff"
                      placeholder="0"
                      onChange={handChange}
                      value={amountSell}
                    />
                    <div className="col-4 p-0" style={{ textAlign: 'right' }}>
                      {
                        approveNftMarket ?
                          <Button variant="primary" onClick={_sellNft}>
                            SELL
                          </Button>
                          :
                          <Button variant="primary" onClick={() => { _approveNft('sell') }}>
                            APPROVE
                          </Button>
                      }
                    </div>
                  </div>

                  :
                  approveNftMining ?
                    <>
                      <Button variant="primary" onClick={() => { _miningNft(6) }}>
                        1 Months
                      </Button>
                      <Button variant="primary" onClick={() => { _miningNft(9) }}>
                        2 Months
                      </Button>
                      <Button variant="primary" onClick={() => { _miningNft(12) }}>
                        3 Months
                      </Button>
                      <Button variant="primary" onClick={() => { _miningNft(15) }}>
                        6 Months
                      </Button>
                    </>
                    :
                    <Button variant="primary" onClick={() => { _approveNft('Staking') }}>
                      APPROVE
                    </Button>
              }
              {/* {
                approveNft ?
                  <Button variant="primary" onClick={() => confirmBuy(2)}>
                    BUSD + SFT
                  </Button>
                  :
                  <Button variant="primary" onClick={() => confirmBuy(2)}>
                    BUSD + SFT
                  </Button>
              } */}

            </Modal.Footer>
          )

        }
        {/* <Modal.Footer>
          <Button variant="secondary" onClick={() => confirmBuy(1)}>
            BUSD
          </Button>
          <Button variant="primary" onClick={() => confirmBuy(2)}>
            BUSD + SFT
          </Button>
        </Modal.Footer> */}
      </Modal>

      {
        showModal ?
          <Modal show={true} onHide={() => { setShowModal(false) }}>
            <Modal.Header closeButton>
              <Modal.Title>Notification</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p style={{}}>Price (BNB)</p>
              <input style={{ width: '100%' }} className="input" type="text" id="newOwner" name="newOwner" value={vlOW} onChange={(e) => setVlOW(e.target.value)} />
            </Modal.Body>
            <Modal.Footer>
              <div className="has-text-centered">
                {
                  loading ?
                    <LoadingButton type="button" className="ant-btn ant-btn-primary btn btn-primary" />
                    :
                    (
                      <button type="submit" className="ant-btn ant-btn-primary btn btn-primary" onClick={_sellNft}>
                        <span>Sell</span>
                      </button>

                    )
                }
              </div>
            </Modal.Footer>
          </Modal>
          : null
      }
    </div >
  );
};

export default NFTs;

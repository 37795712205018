import StakingNfts from "components/staking_nfts";
import React, { useEffect } from "react";
// import { useSelector } from "react-redux";

const StakingNft = () => {
  // const state = useSelector((state) => state);
  useEffect(() => {
    document.title = 'Staking NFTs | AIT';
  });
  return (
    <div className="staking-block__content" style={{ padding: 20 }}>
      {/* <StakingNfts name={"5 Days"} endDay={5} apr={7} />
      <StakingNfts name={"15 Days"} endDay={15} apr={10} />
      <StakingNfts name={"30 Days"} endDay={30} apr={15} />
      <StakingNfts name={"180 Days"} endDay={180} apr={30} /> */}
    </div>
  );
};

export default StakingNft;


import React, { useState, useEffect } from "react";
import CountUp from "react-countup";
import { Link } from "react-router-dom";
import {
  getDataTopTrade,
  getDataTopHold
} from '../../../service';

const Infomation = () => {
  const [disable, setDisable] = useState(true);
  const [dataTopHold, setDataTopHold] = useState([]);
  const [dataTopTrade, setDataTopTrade] = useState([]);
  useEffect(() => {
    let ad = window.location.pathname;
    ad = ad.slice(1)
    ad = ad.charAt(0).toUpperCase() + ad.slice(1);

    document.title = ad + ' | AIT';

  });


  useEffect(async () => {
    let data = await getDataTopTrade();
    let dataHold = await getDataTopHold();
    console.log(data);
    if (data.code != 200) {
      return
    }
    if (dataHold.code != 200) {
      return
    }
    setDataTopTrade(data.data.arrTop)
    // console.log(dataHold.data.arrTop);
    // setDataTopHold(dataHold.data.arrTop)
    let arrHanding = [];
    for (let index = 0; index < dataHold.data.arrTop.length; index++) {
      const element = dataHold.data.arrTop[index];
      // console.log(arrHanding.length, element.adress);
      if (arrHanding.length == 10) {
        continue;
      }
      if (element.adress == "Pinksale: PinkLock V2" ||
        element.adress == "0x675581a8411e3dad65987da10306e79dc43d5392" ||
        element.adress == "Null: 0x000...dEaD" ||
        element.adress == "0xc74f96ed81b4eed5c6c3720bf524b9fd2c21fc03") {
        continue;
      }
      arrHanding.push({
        owner: element.adress,
        volume: Number(element.quantity.replaceAll(',', ""))
      })
    }
    // console.log(arrHanding);
    setDataTopHold(arrHanding)
  }, []);

  const openLink = (url) => {
    // console.log(url);
    window.open(`https://bscscan.com/address/${url}`, "_blank");
  }
  return (
    <div className="staking-block__content pt-4 pb-4" style={{ maxWidth: 1280, margin: "auto" }}>
      <h4 style={{ marginBottom: 50, marginTop: 20, color: "#222222" }}>The event will be released on 20/04/2023!!!</h4>
      {/* <h4 style={{ marginBottom: 50, marginTop: 20, color: "#222222" }}>Session 1 : <span style={{ fontSize: 18, color: "#6a6a6a" }}>2023.04.07 15:00 (UTC) - 2023.04.08 15:00 (UTC)</span></h4>
      <div className="row m-0">
        <div className="col-sm-6">
          <p className="sTitless">Top Trader</p>
          <div className="row m-0 titlese">
            <div className="col-2 Rank">Rank</div>
            <div className="col-4 Address">Address</div>
            <div className="col-3 Volume">Volume</div>
            <div className="col-3 Reward">Reward</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/1.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[0].owner) }}>{dataTopTrade.length < 1 ? "None" : (dataTopTrade[0].owner.slice(0, 4) + "..." + dataTopTrade[0].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 1 ? "None" : dataTopTrade[0].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">350.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/2.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[1].owner) }}>{dataTopTrade.length < 2 ? "None" : (dataTopTrade[1].owner.slice(0, 4) + "..." + dataTopTrade[1].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 2 ? "None" : dataTopTrade[1].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">250.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/3.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[2].owner) }}>{dataTopTrade.length < 3 ? "None" : (dataTopTrade[2].owner.slice(0, 4) + "..." + dataTopTrade[2].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 3 ? "None" : dataTopTrade[2].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">150.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/4.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[3].owner) }}>{dataTopTrade.length < 4 ? "None" : (dataTopTrade[3].owner.slice(0, 4) + "..." + dataTopTrade[3].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 4 ? "None" : dataTopTrade[3].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/5.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[4].owner) }}>{dataTopTrade.length < 5 ? "None" : (dataTopTrade[4].owner.slice(0, 4) + "..." + dataTopTrade[4].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 5 ? "None" : dataTopTrade[4].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/6.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[5].owner) }}>{dataTopTrade.length < 6 ? "None" : (dataTopTrade[5].owner.slice(0, 4) + "..." + dataTopTrade[5].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 6 ? "None" : dataTopTrade[5].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/7.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[6].owner) }}>{dataTopTrade.length < 7 ? "None" : (dataTopTrade[6].owner.slice(0, 4) + "..." + dataTopTrade[6].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 7 ? "None" : dataTopTrade[6].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/8.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[7].owner) }}>{dataTopTrade.length < 8 ? "None" : (dataTopTrade[7].owner.slice(0, 4) + "..." + dataTopTrade[7].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 8 ? "None" : dataTopTrade[7].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank">9</div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[8].owner) }}>{dataTopTrade.length < 9 ? "None" : (dataTopTrade[8].owner.slice(0, 4) + "..." + dataTopTrade[8].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 9 ? "None" : dataTopTrade[8].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">0.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank">10</div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopTrade[9].owner) }}>{dataTopTrade.length < 10 ? "None" : (dataTopTrade[9].owner.slice(0, 4) + "..." + dataTopTrade[9].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopTrade.length < 10 ? "None" : dataTopTrade[9].volume?.toFixed(2)} BNB</div>
            <div className="col-3 Reward">0.00 USDT</div>
          </div>
        </div>
        <div className="col-sm-6">
          <p className="sTitless">Top Holder</p>
          <div className="row m-0 titlese">
            <div className="col-2 Rank">Rank</div>
            <div className="col-4 Address">Address</div>
            <div className="col-3 Volume">Volume</div>
            <div className="col-3 Reward">Reward</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/1.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[0].owner) }}>{dataTopHold.length < 1 ? "None" : (dataTopHold[0].owner.slice(0, 4) + "..." + dataTopHold[0].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 1 ? "None" : dataTopHold[0].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">350.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/2.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[1].owner) }}>{dataTopHold.length < 2 ? "None" : (dataTopHold[1].owner.slice(0, 4) + "..." + dataTopHold[1].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 2 ? "None" : dataTopHold[1].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">250.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/3.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[2].owner) }}>{dataTopHold.length < 3 ? "None" : (dataTopHold[2].owner.slice(0, 4) + "..." + dataTopHold[2].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 3 ? "None" : dataTopHold[2].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">150.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/4.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[3].owner) }}>{dataTopHold.length < 4 ? "None" : (dataTopHold[3].owner.slice(0, 4) + "..." + dataTopHold[3].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 4 ? "None" : dataTopHold[3].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/5.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[4].owner) }}>{dataTopHold.length < 5 ? "None" : (dataTopHold[4].owner.slice(0, 4) + "..." + dataTopHold[4].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 5 ? "None" : dataTopHold[4].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/6.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[5].owner) }}>{dataTopHold.length < 6 ? "None" : (dataTopHold[5].owner.slice(0, 4) + "..." + dataTopHold[5].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 6 ? "None" : dataTopHold[5].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/7.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[6].owner) }}>{dataTopHold.length < 7 ? "None" : (dataTopHold[6].owner.slice(0, 4) + "..." + dataTopHold[6].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 7 ? "None" : dataTopHold[6].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank"><img src="https://thena.fi/images/referral/8.png" /></div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[7].owner) }}>{dataTopHold.length < 8 ? "None" : (dataTopHold[7].owner.slice(0, 4) + "..." + dataTopHold[7].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 8 ? "None" : dataTopHold[7].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">50.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank">9</div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[8].owner) }}>{dataTopHold.length < 9 ? "None" : (dataTopHold[8].owner.slice(0, 4) + "..." + dataTopHold[8].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 9 ? "None" : dataTopHold[8].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">0.00 USDT</div>
          </div>
          <div className="row m-0 contentsrt">
            <div className="col-2 Rank">10</div>
            <div className="col-4 Address" onClick={() => { openLink(dataTopHold[9].owner) }}>{dataTopHold.length < 10 ? "None" : (dataTopHold[9].owner.slice(0, 4) + "..." + dataTopHold[9].owner.slice(-4))}</div>
            <div className="col-3 Volume">{dataTopHold.length < 10 ? "None" : dataTopHold[9].volume?.toFixed(2)} AIT</div>
            <div className="col-3 Reward">0.00 USDT</div>
          </div>
        </div>
      </div> */}
      {/* <div className="brand-title state-name" style={{ fontSize: 40, textAlign: 'center' }}>
        <img src="/assets/images/Coming soon.png" style={{ width: '70%', maxWidth: 600 }} />
      </div> */}
    </div>
  );
};

export default Infomation;

// import React from "react";
import { useSelector } from "react-redux";
import React, { useEffect, useRef, useState } from "react";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { getBalance, get_tree, total_claim } from "service";
import { NotificationManager } from "react-notifications";
import {
  submitApproveMarketRoc,
  checkApproveMarketRoc,
  // submitApproveMarket,
  // checkApproveMarket,
  fcUnSellMarket,
  checkApproveNft,
  getNftsMarket,
  fcBuyMarket,
  getRateMarket
} from '../../../service';

const LoadingButton = ({ className }) => {
  return (
    <button className={className}>
      <div className="spinner-border spinner-border-sm" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </button>
  );
};

const MARKET = (props) => {
  useEffect(() => {
    document.title = 'Market';
  });
  const state = useSelector((state) => state);

  // const [approveSfm, setApproveSfm] = useState(false);
  const [approveRoc, setApproveRoc] = useState(false);


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [approveNftMarket, setApproveNftMarket] = useState(false);
  const [sttIsMy, setSttIsMy] = useState(false);
  const [optionClass, setOptionClass] = useState('0');
  const [optionPrice, setOptionPrice] = useState('all');

  // const [balanceSfm, setBalanceSfm] = useState(0);
  const [balanceRoc, setBalanceRoc] = useState(0);

  const [amountSell, setAmountSell] = useState(0);
  const [myNft, setMyNft] = useState([]);
  const [dataRender, setDataRender] = useState([]);
  const [isMyNft, setIsMyNft] = useState([]);
  const [isData, setIsData] = useState({});
  const [dataDetail, setDataDetail] = useState({});
  // const [dataRate, setDataRate] = useState({ rateSft: 384, rateSfm: 3.8 });
  // const [treeView, setTreeView] = useState([]);
  // const [refBy, setRefBy] = useState("");


  // useEffect(() => {
  //   if (state.address_web3) {
  //     setInterval(async () => {
  //       if (!state.address_web3) {
  //         return;
  //       }
  //       try {
  //         const res = await fetch(`https://us-central1-safaty-e20ba.cloudfunctions.net/getUserStaking?address=${state.address_web3}`).then((res) => res.json());
  //         console.log(res.stakeInfo);
  //         if (res.stakeInfo.unpaid_balanceOld_ido_v3 == undefined) {
  //           res.stakeInfo.unpaid_balanceOld_ido_v3 = [0, 0]
  //         }
  //         setRefBy(res.stakeInfo.refer_by);
  //       } catch (err) {
  //         setRefBy('none');
  //         console.log(`err`, err);
  //         return false;
  //       }
  //     }, 5000);
  //   }
  // }, [state.address_web3]);

  useEffect(() => {
    changeData(optionPrice, optionClass, myNft, isMyNft)
  }, [myNft]);

  useEffect(() => {
    changeData(optionPrice, optionClass, myNft, isMyNft)
  }, [optionClass]);

  useEffect(() => {
    changeData(optionPrice, optionClass, myNft, isMyNft)
  }, [optionPrice]);

  useEffect(() => {
    changeData(optionPrice, optionClass, myNft, isMyNft)
  }, [sttIsMy]);


  const changeData = async (optionPrice, optionClass, myNfts, isMyNfts) => {
    if (isMyNfts == undefined) {
      return
    }
    // console.log(optionPrice, optionClass, myNfts, isMyNfts);
    // let myNft = myNft
    let myNft = [];
    if (!sttIsMy) {
      myNft = myNfts
    } else {
      myNft = isMyNfts
    }
    // console.log(myNft);
    // return;
    let arrIsClass = myNft.filter(data => {
      return ((data.story == optionClass) || optionClass == '0')
    })
    let arrIsPrice = arrIsClass;
    if (optionPrice == 'Lowest') {
      arrIsPrice = arrIsPrice.sort(function (a, b) {
        return a.price - b.price;
      });
    } else if (optionPrice == 'Highest') {
      arrIsPrice = arrIsPrice.sort(function (a, b) {
        return b.price - a.price;
      });
    }
    setDataRender(arrIsClass);
  }


  useEffect(() => {
    getNft()
    // getData()
    setInterval(() => {
      getNft()
      // getData()
    }, 5000);
  }, []);


  const getNft = async () => {
    const _getNftsMarket = await getNftsMarket();
    const _getBnbMarket = await getBalance();
    if (!_getNftsMarket.data) {
      return
    }
    let arrIsMy = [];
    let minss = 9999999999999999999999999999999999999999, maxs = 0;

    for (let index = 0; index < _getNftsMarket?.data.length; index++) {
      const element = _getNftsMarket?.data[index];
      // console.log(element);
      if (element.price * 1 <= minss) {
        minss = element.price * 1
      }
      if (element.price * 1 >= maxs) {
        maxs = element.price * 1
      }

      if (element.author.toLocaleLowerCase() == _getNftsMarket.address.toLocaleLowerCase()) {
        arrIsMy.push(element);
      }
    }


    setMyNft(_getNftsMarket?.data)
    changeData(optionPrice, optionClass, _getNftsMarket?.data)
    setIsMyNft(arrIsMy)
    setDataDetail({ floorPrice: maxs / 1e18, bestPrice: minss / 1e18, volume: _getBnbMarket * 100 / 15, listing: _getNftsMarket?.data?.length })
    // console.log(minss / 1e18, maxs / 1e18, _getBnbMarket * 100 / 15, _getNftsMarket?.data?.length);
    // let minss = 0, maxs = 0;
    // for (let index = 0; index < array.length; index++) {
    //   const element = array[index];

    // }
    // let arfds = _getNftsMarket.data;
    // // arfds = 0
    // console.log(arfds);
    // let arrrrrr = arfds.sort(function (a, b) {
    //   // return a.price - b.price;
    // });
    // console.log(arfds);
    // console.log(arfds[0], arfds[arfds.length]);
    // setDataDetail(arfds)

    // console.log(_getNftsMarket?.data);
  };

  const getData = async () => {
    // const approveRoc = await checkApproveMarketRoc();
    // // console.log(rate);
    // setApproveRoc(approveRoc?.stt || false);
    // setBalanceRoc(approveRoc?.balance || 0)

  };


  const _approveRoc = async () => {
    setLoading(true)
    await submitApproveMarketRoc();
    setLoading(false);
  };

  const sell = async (data, types) => {
    // data.typeCuren = 'sell';
    // data.typeBuy = types;
    // data = setIssdata(data)
    // console.log(data);
    // const _checkApproveNft = await checkApproveNft(data.id);
    // console.log(_checkApproveNft);
    // setApproveNftMarket(_checkApproveNft.sttMarket)
    // setIsData(data)
    // // // return
    handleShow();
  }


  const _buyNft = async (datas) => {
    setLoading(true)
    let data = await fcBuyMarket(datas.tokenId, datas.price);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Buy Success", "Success", 10000);
      handleClose()
    }
    setLoading(false);
  };

  const changeOptionPrice = async (val) => {
    await setOptionPrice(val)
  }

  const changeOptionClass = async (val) => {
    await setOptionClass(val)
  }

  const _unSell = async (datas) => {
    // console.log(datas);
    // console.log(isData.id, isData.price);
    // // return
    setLoading(true)
    let data = await fcUnSellMarket(datas.tokenId);
    if (!data) {
      NotificationManager.error("Error", "Error", 10000);
    } else {
      NotificationManager.success("Success", "Success", 10000);
      handleClose()
    }
    setLoading(false);
  }

  // console.log(state);
  return (
    <div className="site-main-content__buy-sft" >
      <div className="container">
        <div className="lslslslslfoasdwe mt-4">
          <div className="oolwekas">
            <div className="ooklskgfe">
              {dataDetail?.volume} <span className="loldlfefew">BNB</span>
            </div>
            <div className="llfdswa">
              Total volume
            </div>
          </div>
          <div className="oolwekas">
            <div className="ooklskgfe">
              {dataDetail?.floorPrice} <span className="loldlfefew">BNB</span>
            </div>
            <div className="llfdswa">
              Floor price
            </div>
          </div>
          <div className="oolwekas">
            <div className="ooklskgfe">
              {dataDetail?.bestPrice} <span className="loldlfefew">BNB</span>
            </div>
            <div className="llfdswa">
              Best offer
            </div>
          </div>
          <div className="oolwekas">
            <div className="ooklskgfe">
              {dataDetail?.listing} <span className="loldlfefew">NFTs</span>
            </div>
            <div className="llfdswa">
              Listing
            </div>
          </div>
        </div>

        <div className="row manage-your-wallet mt-4">
          <div className="row m-0 p-0">
            <div className="col-8 p-0">
              <span className="mbsde mr-2" style={{ color: '#252525' }}>Sort By:</span>
              <select className="selecssrs" value={optionPrice} onChange={(event) => { changeOptionPrice(event.target.value) }}>
                <option value="Default">Default</option>
                <option value="Highest">Highest Price</option>
                <option value="Lowest">Lowest Price</option>
              </select>
            </div>
            {
              !sttIsMy ?
                <button onClick={() => { setSttIsMy(true) }} className="col-4 btn btn-primary btn-connect p-0" type="button" style={{ border: 0, maxWidth: 150 }}><span className="button-title">My</span></button>
                :
                <button onClick={() => { setSttIsMy(false) }} className="col-4 btn btn-primary btn-connect p-0" type="button" style={{ border: 0, opacity: 0.3, maxWidth: 150 }}><span className="button-title">My</span></button>
            }
            {/* <button className="col-2">My Nfts</button> */}
          </div>

          {
            (dataRender.length) == 0 ?
              <h3 style={{ textAlign: 'center', marginTop: 70 }}>No data!!!</h3>
              :
              dataRender.map((data, key) => {
                return (
                  <div className="col col-sm-3 mt-4" key={key}>

                    <div className="sadasdasds">
                      <div className="sadds">
                        <img className="imgsss" src={`/assets/images/nfts/${data.tokenId}.png`} />
                        <div className="price mb-2 mt-2">ID: {data.tokenId}</div>
                        <div className="price mb-2 mt-2">PRICE: {(data?.price / 1e18)} BNB</div>
                        <div className="row m-0 harvest">
                          {
                            loading ? (
                              <LoadingButton className="btn btn-primary" />
                            ) : (
                              data?.author == state?.address ?
                                <div className="col-12 p-0" onClick={() => { _unSell(data) }}>
                                  <button className="btn btn-lg btn-block btn-background-ghost">Un Sell</button>
                                </div>
                                :
                                <div className="col-12 p-0" onClick={() => { _buyNft(data) }}>
                                  <button className="btn btn-lg btn-block btn-background-ghost">BUY</button>
                                </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
          }
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: '#ff4d4d' }}>
          <div style={{ margin: 'auto', textAlign: 'center', marginBottom: 10, position: 'relative' }}>
            <img className="imgsss" src={`/assets/images/nfts/${isData.tokenId}.png`} style={{ width: '70%' }} />
          </div>
        </Modal.Body>
        {
          loading ? (
            <LoadingButton className="btn btn-primary" />
          ) : (
            <Modal.Footer>
              {/* <div>
                Price: 111 SFm
              </div> */}
              {
                sttIsMy ?
                  <Button variant="primary" onClick={_unSell}>
                    Un Sell
                  </Button>
                  : (

                    approveRoc ?
                      <Button variant="primary" onClick={_buyNft}>
                        BUY ( {Number(isData?.price).toFixed()} AIT)
                      </Button>
                      :
                      <Button variant="primary" onClick={_approveRoc}>
                        APPROVE
                      </Button>


                  )
              }

            </Modal.Footer>
          )

        }
      </Modal>
    </div >
  );
};

export default MARKET;
